<template src="./AcademicPlant.html"></template>

<script>
import ArrobaMedellinSeeker from "@/components/ArrobaMedellinSeeker/ArrobaMedellinSeeker";
import idiService from "../../services/Idi";
import ChooseUniversity from "../../components/ChooseUniversity/ChooseUniversity";
import { mapGetters } from "vuex";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "academicPlant",
  components: {
    ArrobaMedellinSeeker,
    ChooseUniversity,
    ArrobaMedellinArrobitaNoData,
  },
  props: {
    TitleNotFound: {
      type: String,
      default: "Lista de docentes",
    },
    notFound: {
      type: String,
      default: "No hay docentes en éste momento",
    },
  },
  data() {
    return {
      errorMessage: false,
      countAcademicPlant: {},
      teachers: [],
      showMessageRemovePermissions: "",
      institutionId: "",
      modalGivePermissions: false,
      teacher: { id: null },
      role: "",
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.infoAcademicPlant();
  },
  methods: {
    buttonRemovePermissions(index) {
      this.showMessageRemovePermissions = index;
    },
    showForMessageRemovePermissions(index) {
      if (index === this.showMessageRemovePermissions) {
        return true;
      } else {
        return false;
      }
    },
    buttonBackRemovePermissions() {
      this.showMessageRemovePermissions = "";
    },
    async infoAcademicPlant() {
      try {
        this.countAcademicPlant = await idiService.countAcademicPlant(
          this.institutionId
        );
        this.teachers = await idiService.getUserTeacherByInstitution(
          this.institutionId
        );
      } catch (error) {
        console.error(error);
      }
    },
    redirectProfileUser(path, follow) {
      let routeData = this.$router.resolve({
        name: path,
        params: { id: follow },
      });
      window.open(routeData.href, "_blank");
    },
    showModalGivePermissions(teacher) {
      this.teacher = teacher;
      this.modalGivePermissions = true;
    },
    async givePermissions() {
      try {
        const obj = {
          tipo_rol: "TeacherInvestigator",
        };
        await idiService.updateUserCampus(obj, this.teacher.id);
        this.$buefy.toast.open({
          duration: 5000,
          message: "Se actualizaron los permisos correctamente",
          position: "is-bottom",
          type: "is-success",
        });
        this.infoAcademicPlant();
        this.showMessageRemovePermissions = false;
        this.modalGivePermissions = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    roleValidationAdministrator() {
      return ["Administrator"].some((a) => a === this.role);
    },
    ...mapGetters({
      institution: "getInstitutionSelected",
    }),
  },
  watch: {
    institution: async function (value) {
      this.institutionId = value;
      await this.infoAcademicPlant();
    },
  },
};
</script>

<style lang="scss" scoped src="./AcademicPlant.scss"></style>